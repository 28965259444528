import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";

import { RestService } from "../common/rest.service";
import { RequestConfirmationDialogComponent } from "../request-confirmation-dialog/request-confirmation-dialog.component";
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";
import { MsalAuthService } from "../msal-auth.service";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-create-user-role",
  templateUrl: "./create-user-role.component.html",
  styleUrls: ["./create-user-role.component.scss"],
})
export class CreateUserRoleComponent implements OnInit {
  approvalForm: FormGroup;
  rolesOptions: string[] = ["Site Admin", "Site User"];
  statusValues: string[] = ["Active", "Inactive"];

  isApplicationCheckedArray: any[] = [];
  superAdminOption: boolean = false;

  editMode: boolean = false;
  projectVerified: boolean;
  showStatus: boolean;
  otherUsers = false;
  projectArray: any[];
  projectSiteAdmin: any[];
  projectSuperAdmin: any[];
  filteredOptions: Observable<string[]>;

  verifyButnDisable: boolean;

  userRole: string;

  projectIdOrNameObj: string;
  requestButnDisable: boolean;
  unselectedApps: any[] = [];

  roles: any[];
  superAdmin: boolean;
  projectInputControl: FormControl;
  form: FormGroup;
  submitted = false;
  currentUser: any;
  userList: any[] = [];

  userLoggedIn = false;
  userName: string;
  apps: { name: string; disabled?: boolean; checked?: boolean }[] = [];
  isSelected = true;
  gidError: string;
  projectInput: string | null = null;
  applicationsFetched: boolean = false;
  isEditMode = false;
  showApplications = false;
  showFetchButton = false;
  showApps: boolean = true;
  formInteracted: boolean = false;
  isSuperAdminUser: boolean = false;
  isSiteAdminUser: boolean = false;
  isSpinnerActive: boolean = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private restService: RestService,
    private restApi: RestService,
    private msalAuth: MsalAuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,

    private cookie: CookieService
  ) {}

  ngOnInit(): void {
    this.checkUserRole();

    this.userLoggedIn = this.msalAuth.loggedIn;
    this.userName = this.msalAuth.loggedInUser;
    if (sessionStorage.getItem("userName")) {
      this.userLoggedIn = true;
      this.userName = sessionStorage.getItem("userName");
      this.currentUser = sessionStorage.getItem("currentUser");
    }
    this.loadUserData();

    this.approvalForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],

      emailId: ["", [Validators.required, Validators.email]],
      gid: ["", Validators.required],

      orgName: ["", Validators.required],
      role: ["", Validators.required],
      projectIdOrName: ["", Validators.required],
      remarks: [""],

      selectedApps: this.formBuilder.control([], []),
      unselectedApps: [],
    });
    this.projectInputControl = new FormControl();
    this.superAdmin = false;
    this.otherUsers = false;
    this.requestButnDisable = true;
    this.verifyButnDisable = true;

    this.approvalForm.get("role").valueChanges.subscribe((roleValue) => {
      const selectedAppsControl = this.approvalForm.get("selectedApps");

      if (roleValue === "Site Admin") {
        selectedAppsControl.clearValidators();
      } else {
        selectedAppsControl.setValidators(Validators.required);
      }
      selectedAppsControl.updateValueAndValidity();
    });

    this.approvalForm.get("gid").valueChanges.subscribe((gidValue) => {
      const emailId = this.approvalForm.get("emailId").value;
      const gidControl = this.approvalForm.get("gid");

      if (emailId.endsWith("@siemens-energy.com")) {
        if (!gidValue) {
          this.gidError = "GID is required for Siemens email.";
        } else if (!/^(Z004|z004)[A-Za-z0-9]{4}$/.test(gidValue)) {
          this.gidError =
            "GID format should be Z004 followed by 4 alphanumeric characters.";
        } else {
          this.gidError = null;
        }
      } else if (emailId) {
        if (!gidValue) {
          this.gidError = "GID is required.";
        } else if (!/^(XS00|xs00)[A-Za-z0-9]{4}$/.test(gidValue)) {
          this.gidError =
            "GID format should be XS00 followed by 4 alphanumeric characters.";
        } else {
          this.gidError = null;
        }
      } else {
        this.gidError = null;
      }

      gidControl.setErrors(this.gidError ? { customError: true } : null);
    });

    // Subscribe to emailId changes to reset GID validation as necessary
    this.approvalForm.get("emailId").valueChanges.subscribe(() => {
      this.approvalForm.get("gid").updateValueAndValidity();
    });

    this.getAllProjectDetails();

    this.filteredOptions = this.approvalForm
      .get("projectIdOrName")
      .valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
  }

  checkUserRole() {
    this.restService.getCurrentUserRole().subscribe(
      (roles: string[]) => {
        this.isSuperAdminUser = roles.includes("Super Admin");
        this.isSiteAdminUser = roles.includes("Site Admin");
      },
      (error) => {
        console.error("Error fetching user roles:", error);
      }
    );
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  loadUserData() {
    console.log("Loading user data...");

    this.restService.getCurrentUserRole().subscribe(
      (data: string[]) => {
        if (data.includes("Super Admin")) {
          this.filteredOptions = this.projectInputControl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filter(value))
          );
          this.getAllProjectDetails();
        } else if (data.includes("Site Admin")) {
          this.getUserProjects();
        } else {
          console.warn("No valid role found for the current user.");
        }
      },
      (error) => {
        console.error("Error fetching user roles:", error);
      }
    );
  }
  syncApplications() {
    this.toastr.info("Application Sync started in the background...");

    this.isSpinnerActive = true;

    setTimeout(() => {
      this.spinner.show();
    }, 5000);

    this.restApi.syncAllApp().subscribe(
      (data) => {
        this.toastr.success(
          "User Created Successful! Permissions are successfully updated and synced to the downstream application.",
          null,
          {
            timeOut: 3000,
          }
        );
        setTimeout(() => {
          this.spinner.hide();
          this.isSpinnerActive = false;
        }, 3000);

        window.top.location.reload();
      },
      (error) => {
        if (error && error.error) {
          this.toastr.error(error.error.error, "HTTP Status: " + error.status);
        } else {
          this.toastr.error(
            "An error occurred",
            "HTTP Status: " + error.status
          );
        }

        this.spinner.hide();
        this.isSpinnerActive = false;
      }
    );
  }

  onEditUser() {
    if (
      this.currentUser === "Super Admin" ||
      this.currentUser === "Site Admin"
    ) {
    }
  }

  private _filter(value: string): any {
    if (!this.projectArray) {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this.projectArray.filter(
      (project) =>
        project.name.toLowerCase().includes(filterValue) ||
        project.code.toLowerCase().includes(filterValue)
    );
  }

  getUncheckedApps(appName: string, isChecked: boolean, selected) {
    const index = this.apps.findIndex((app) => app.name === appName);
    if (index >= 0) {
      this.apps[index].checked = selected;
    }
    if (!isChecked && !this.unselectedApps.includes(appName)) {
      this.unselectedApps.push(appName);
    } else if (isChecked && this.unselectedApps.includes(appName)) {
      const index = this.unselectedApps.indexOf(appName);
      this.unselectedApps.splice(index, 1);
    }
  }

  onProjectNameChange() {
    this.projectVerified = false;
    this.requestButnDisable = true;
    this.verifyButnDisable = false;
  }

  roleChangeHandler(event: any) {
    if (event.value === "Super Admin") {
      this.handleSuperAdminRole();
    } else {
      this.handleOtherRoles();
    }
  }
  handleSuperAdminRole() {
    this.superAdmin = true;
    this.otherUsers = false;
    this.requestButnDisable = false;
    this.projectVerified = false;
  }
  handleOtherRoles() {
    this.otherUsers = true;
    this.superAdmin = false;
    this.requestButnDisable = true;
    this.projectVerified = false;
  }
  onProjectCleared(): void {
    const projectControl = this.approvalForm.get("projectIdOrName");
    if (!projectControl.value) {
      this.projectVerified = false; // Hide applications if the project is cleared
      this.approvalForm.controls["selectedApps"].reset(); // Optionally reset selected apps
    }
  }
  onProjectSelected(project: any): void {
    if (project) {
      this.verify(project);

      // Update the form control value with the selected project object
      this.approvalForm
        .get("projectIdOrName")
        .setValue(project, { emitEvent: false });

      this.showApplications = true;
    } else {
      this.showApplications = false;
    }
  }

  verify(project: any): void {
    if (!project || !project.code || !project.name) {
      this.toastr.warning(
        "Invalid project selection. Please select a valid project."
      );
      this.projectVerified = false;
      this.showApplications = false; // Ensure applications are hidden
      return;
    }

    this.applicationsFetched = true;
    this.projectVerified = true;

    // Simulate fetching applications
    this.apps = project.apps || [];
    this.showApplications = true;

    // Update the form control with the project code and name without triggering valueChanges
    const projectDisplayValue = `${project.code} - ${project.name}`;
    this.approvalForm
      .get("projectIdOrName")
      .setValue(projectDisplayValue, { emitEvent: false });
    // Fetch project details
    this.restService.getProject(project.code).subscribe(
      (result) => {
        let projectHasLOP = false;

        result.apps.forEach((app) => {
          if (app.hasOwnProperty("role")) {
            this.userRole = app.role;
          }
          if (app.name === "LOP") {
            projectHasLOP = true;
          }
        });

        this.projectIdOrNameObj = result.project;
        this.projectVerified = true;
        this.requestButnDisable = false;

        if (projectHasLOP) {
          const projectCode = result.project.code;
          this.restService
            .getUserRoleInIcmmsLop(projectCode)
            .subscribe((res) => {
              const role = res.response;
              this.apps = [];

              if (role === "CONTRACTOR") {
                result.apps.forEach((app) => {
                  if (app.name.toUpperCase() === "LOP") {
                    this.apps.push(app);
                  }
                });

                if (this.approvalForm.get("role").value !== "Site User") {
                  this.approvalForm.get("role").setValue("Site User");
                }

                this.toastr.info("LOP contractor can access only LOP App", "", {
                  timeOut: 8000,
                });
              } else if (role === "NOT FOUND" || role === "ERROR") {
                this.apps = result.apps.map((app) => {
                  if (app.name.toUpperCase() === "LOP") {
                    app.disabled = true;
                  }
                  return app;
                });

                if (role === "ERROR") {
                  this.toastr.warning(
                    "Request access for LOP app is restricted due to error in ICMMS system",
                    "",
                    { timeOut: 8000 }
                  );
                }
              } else {
                this.apps = result.apps;
              }
            });
        } else {
          this.apps = result.apps;
        }
      },
      (error) => {
        this.toastr.error(error.error.message);
        this.projectVerified = false;
        this.requestButnDisable = true;
      }
    );

    this.unselectedApps = [];
    console.log("Verify called with:", project);
  }

  getAllProjectDetails() {
    this.restService.getAllProjectDetails().subscribe(
      (res) => {
        this.projectSuperAdmin = res.filter(
          (p) => p.name !== "ALL" && p.status == "ACTIVE"
        );
      },
      (error) => {
        console.error("Error fetching project details:", error);
      }
    );
  }
  getUserProjects() {
    this.restService.getUserProjects().subscribe(
      (res) => {
        this.projectSiteAdmin = res.filter(
          (p) => p.name !== "ALL" && p.status === "ACTIVE"
        );
      },
      (error) => {
        console.error("Error fetching user projects:", error);
      }
    );
  }

  onChangeEventFunc(applicationName: string, selected: boolean) {
    const appIndex = this.isApplicationCheckedArray.findIndex(
      (app) => app.applicationName === applicationName
    );
    if (appIndex >= 0) {
      this.isApplicationCheckedArray[appIndex].checkboxValue = selected;
    }
  }

  handleFormErrors(): void {
    const emailId = this.approvalForm.get("emailId").value;
    const gidControl = this.approvalForm.get("gid");

    if (!emailId || this.approvalForm.get("emailId").hasError("email")) {
      this.toastr.error("Please enter a valid Email ID.");
      return;
    }

    if (emailId.endsWith("@siemens-energy.com")) {
      if (!gidControl.value) {
        this.gidError = "GID is required for Siemens email.";
        gidControl.setErrors({ customError: true });
      } else if (!/^(Z004|z004)[A-Za-z0-9]{4}$/.test(gidControl.value)) {
        this.gidError =
          "GID format should be Z004 followed by 4 alphanumeric characters.";
        gidControl.setErrors({ customError: true });
      } else {
        this.gidError = null;
        gidControl.setErrors(null);
      }
    } else if (emailId) {
      if (!gidControl.value) {
        this.gidError = "GID is required.";
        gidControl.setErrors({ customError: true });
      } else if (!/^(XS00|xs00)[A-Za-z0-9]{4}$/.test(gidControl.value)) {
        this.gidError =
          "GID format should be XS00 followed by 4 alphanumeric characters.";
        gidControl.setErrors({ customError: true });
      } else {
        this.gidError = null;
        gidControl.setErrors(null);
      }
    }

    gidControl.markAsTouched();
  }

  validateForm(): boolean {
    this.submitted = true;

    // Check required fields
    if (!this.approvalForm.get("firstName").value) {
      this.toastr.error("First Name is required.");
      return false;
    }

    if (!this.approvalForm.get("lastName").value) {
      this.toastr.error("Last Name is required.");
      return false;
    }

    if (!this.approvalForm.get("emailId").value) {
      this.toastr.error("Email ID is required.");
      return false;
    }

    // Handle dynamic GID validation
    this.handleFormErrors();
    if (this.gidError) {
      return false;
    }

    if (!this.approvalForm.get("role").value) {
      this.toastr.error("Role is required.");
      return false;
    }

    const projectControl = this.approvalForm.get("projectIdOrName");
    if (projectControl.touched && !projectControl.value) {
      this.toastr.error("Project is required.");
      return false;
    }

    if (this.showFetchButton && !this.applicationsFetched) {
      this.toastr.warning("Please fetch applications before submitting.");
      return false;
    }

    const selectedAppsControl = this.approvalForm.get("selectedApps");

    if (
      selectedAppsControl &&
      (!selectedAppsControl.value || selectedAppsControl.value.length === 0)
    ) {
      this.toastr.warning("At least one application must be selected.");
      return false;
    }

    return true;
  }

  onSubmit(): void {
    this.submitted = true;

    this.approvalForm.markAllAsTouched();

    if (!this.validateForm()) {
      return;
    }

    if (this.approvalForm.invalid) {
      this.handleFormErrors();
      return;
    }

    // Extract the project as an object
    const originalProject = this.approvalForm.get("projectIdOrName").value;

    // Ensure the project is valid and extract code and name
    if (!originalProject || !originalProject.code || !originalProject.name) {
      this.toastr.error(
        "Invalid project selection. Please select a valid project."
      );
      return;
    }

    // Set the code (or any other key needed for submission) to the form control
    this.approvalForm.get("projectIdOrName").setValue(originalProject.code);

    console.log(
      "Form Values before submission:",
      JSON.stringify(this.approvalForm.value, null, 2)
    );

    this.restService.createUser(this.approvalForm.value).subscribe(
      (res) => {
        if (res.message === "Created successfully") {
          this.syncApplications();
          console.log("Creating User....");

          // Restore the original project object back to the form control
          this.approvalForm.get("projectIdOrName").setValue(originalProject);
        } else {
          this.toastr.error(
            res.message || "An unexpected error occurred.",
            null,
            { timeOut: 3000 }
          );
        }
      },
      (error) => {
        console.error("Error during API call:", error);

        this.toastr.error(
          error.error.message ||
            "An unexpected error occurred. Please try again.",
          null,
          { timeOut: 3000 }
        );

        this.resetForm();
      }
    );
  }

  resetForm(): void {
    this.approvalForm.reset({
      role: "",
      projectIdOrName: "",
      selectedApps: this.approvalForm.get("selectedApps").value,
    });

    this.approvalForm.markAsPristine();
    this.approvalForm.markAsUntouched();

    Object.keys(this.approvalForm.controls).forEach((key) => {
      const control = this.approvalForm.get(key);
      if (control) {
        control.setErrors(null);
        control.markAsUntouched();
        control.markAsPristine();
      }
    });

    this.submitted = false;
    this.gidError = null;
    this.showApps = false;
    this.showFetchButton = false;
    this.applicationsFetched = false;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.approvalForm.controls;
  }

  onCancel(): void {
    this.submitted = true;
  }
}
